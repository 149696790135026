import React from 'react';
import cn from 'classnames';
import useRouter from '@/Framework/hooks/useNextRouter';
import Favicon from './Favicon';
import Header from '@/dataroom/ui/components/Landing/Legacy/Sections/Header';
import CookiesBanner from '@/Framework/UI/Molecules/CookiesBanner';
import Footer from './Footer';
import BookDemoButton from '@/dataroom/ui/common/BookDemoButton';
import ScrollBodyBackground from '@/Framework/UI/Templates/ScrollBodyBackground';
import TenantContext from '@/Framework/Tenant/TenantContext';
import config from '@/dataroom/application/config/config';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import stickyFooterStyles from '@/Framework/UI/Organisms/StickyFooter/stickyFooter.scss';
import headerStyles from '@/dataroom/ui/components/Landing/Legacy/Sections/Header/header.scss';
import styles from './tenant17g5Styles.scss';
import logo from '../../../assets/17g5LogoText.svg';

interface IProps {
  children: React.ReactNode,
}

const headerMenu = [
  {
    key: 'overview',
    title: 'The Rule',
    path: '/overview',
  },
  {
    key: 'pricing',
    title: 'Pricing',
    path: '/pricing',
  },
];

const Tenant17g5 = ({ children }: IProps) => {
  const tenant = config.tenant.tenant17g5.code;
  const { asPath: pathname } = useRouter();

  const loginUrl = dataroomUrl(tenant).getLoginUrl(
    dmPortalUrl.getUrl(`/${ tenant }`),
  );

  const registerUrl = dataroomUrl(tenant).getRegisterUrl(
    dataroomUrl(tenant).getUrl(pathname),
  );

  return (
    <TenantContext tenant={ tenant }>
      <Favicon />
      <Header
        tenant={ tenant }
        logo={ logo }
        menu={ headerMenu }
        button={ () => (
          <BookDemoButton legacy className={ headerStyles.menuBtn } />
        ) }
        loginUrl={ loginUrl }
        registerUrl={ registerUrl }
        isNext
      />
      <div className={ cn(styles.pageContainer, stickyFooterStyles.pageContainerWithStickyFooter) }>
        <ScrollBodyBackground tenant={ config.tenant.tenant17g5.code } />
        { children }
      </div>
      <Footer tenant={ tenant } />
      <CookiesBanner />
    </TenantContext>
  );
};

export default Tenant17g5;
